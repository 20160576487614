<template>
  <div class="projectList">
    <el-card class="mb-16">
      
      <div class="search-container">
        <el-form
          size="small"
          label-position="right"
          class="migration-search"
          style="border: none"
          inline
        >
          <!-- 公司名称/简称  -->
          <el-form-item class="inline" label="名称：">
            <el-input
              style="width: 220px; margin-right: 15px"
              v-model="searchForm.projectName"
              placeholder="请输入公司名称/简称"
              clearable
              @keyup.native.enter="handleSearch"
              maxlength="17"
            />
          </el-form-item>
          <!-- 上下架 -->
          <el-form-item class="inline" label="状态：">
            <el-select
              style="width: 220px; margin-right: 15px"
              v-model="searchForm.projectStatus"
              placeholder="请选择状态"
              filterable
              clearable
            >
            <el-option label="上架" value="2" ></el-option>
            <el-option label="下架" value="3" ></el-option>
            </el-select>
          </el-form-item>
          <!-- 热门 -->
          <el-form-item class="inline" label="热门：">
            <el-select
              style="width: 220px; margin-right: 15px"
              v-model="searchForm.isHot"
              placeholder="请选择是否热门"
              filterable
              clearable
            >
            <el-option label="是" value="1" ></el-option>
            <el-option label="否" value="0" ></el-option>
            </el-select>
          </el-form-item>
          <!-- 推荐 -->
          <el-form-item class="inline" label="推荐：">
            <el-select
              style="width: 220px; margin-right: 15px"
              v-model="searchForm.isRecommend"
              placeholder="请选择是否推荐"
              filterable
              clearable
            >
            <el-option label="是" value="1" ></el-option>
            <el-option label="否" value="0" ></el-option>
            </el-select>
          </el-form-item>
          <!-- 行业 -->
          <el-form-item class="inline" label="行业：">
            <el-select
              style="width: 220px; margin-right: 15px"
              v-model="searchForm.industry"
              placeholder="请选择行业"
              filterable
              clearable
            >
              <el-option
                v-for="item in industryTypeList"
                :label="item.labelName"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 地区 -->
          <el-form-item class="inline" label="地区：">
            <el-select
              style="width: 220px; margin-right: 15px"
              v-model="searchForm.cityCode"
              placeholder="请选择地区"
            >
              <el-option-group
                v-for="addressItem in addressList"
                :key="addressItem.id"
                :label="addressItem.name"
              >
                <el-option
                  v-for="item in addressItem.children"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
          <!-- 营收 -->
          <el-form-item class="inline" label="营收：">
            <el-select
              style="width: 220px; margin-right: 15px"
              v-model="searchForm.businessIncome"
              placeholder="请选择营收"
              filterable
              clearable
            >
              <el-option
                v-for="item in businessIncomeList"
                :label="item.labelName"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 净利规模 -->
          <el-form-item class="inline" label="净利规模：">
            <el-select
              style="width: 220px; margin-right: 15px"
              v-model="searchForm.netProfit"
              placeholder="请选择净利规模"
              filterable
              clearable
            >
              <el-option
                v-for="item in netProfitList"
                :label="item.labelName"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-button
            type="primary"
            size="medium"
            @click="handleSearch"
            :disabled="isSearch"
            >查询</el-button
          >
          <el-button size="medium" @click="handleReset" plain>重置</el-button>
        </el-form>
      </div>
    </el-card>
      
    <el-card class="mb-16">
      <div class="tabHeader" v-if="user.userType == 1">
        <p>共{{total}}条数据</p>
        <!-- <el-button type="primary" @click="drawer = true">新建项目</el-button> -->
      </div>
      <myTable
        class="my-table"
        ref="myTable"
        :tableData="tableData"
        :tableColumns="tableColumns"
        tableBorder
        :page="pageInfo.pageNum"
        :rows="pageInfo.pageSize"
        :total="total"
        isPagination
        @handleSortChange="handleSortChange"
        @handleChange="handleChange"
        @handleSelectionChange="handleSelectionChange"
      >
        <el-table-column slot="name" label="项目名称" width="240px" fixed="left">
          <template slot-scope="{ row }">
            <div class="title-box">
              <el-image style="width: 60px; height: 80px" :src="row.url">
              </el-image>
              <div class="title-right">
                <p class="title">{{ row.name }}</p>
                <p class="content">{{ row.desc }}</p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          slot="operate"
          label="操作"
          fixed="right"
          width="240px"
          align="center"
        >
          <template slot-scope="{ row }">
            <!-- <el-button
              @click="() => handleOperation(row, operate.DOWNLOAD)"
              type="text"
              >查看下载</el-button
            > -->
            <!-- <el-button
              v-show="row.projectStatus == 2"
              @click="() => handleOperation(row, operate.COLLECT)"
              type="text"
              >{{row.collected == 1 ? '取消收藏':'收藏'}}</el-button
            > -->
            <el-button
              @click="() => handleOperation(row, operate.DETAIL)"
              type="text"
              >详情</el-button
            >

            <el-button
              @click="() => handleOperation(row, operate.EDIT)"
              type="text"
              >编辑</el-button
            >
            <el-button
              v-if="user.userType == 1"
              @click="() => handleOperation(row, operate.DELETE)"
              type="text"
              >{{ row.projectStatus == 2 ? '下架' : '上架' }}</el-button
            >
            <el-button
              v-if="user.userType == 1"
              @click="() => hotAndRecommend(row, 'isRecommend')"
              type="text"
              >{{`${row.isRecommend == 1 ? '取消' : '设置'}`}}推荐</el-button
            >
            <el-dropdown v-if="user.userType == 1">
              <el-button type="text" style="margin-left: 10px;">更多</el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <el-button
                    v-if="user.userType == 1"
                    @click="() => handleTop(row)"
                    type="text"
                    >{{ row.isTop ? '取消置顶' : '置顶' }}</el-button
                  >
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-button
                    v-if="user.userType == 1"
                    @click="() => hotAndRecommend(row, 'isHot')"
                    type="text"
                    >{{`${row.isHot == 1 ? '取消' : '设置'}`}}热门</el-button
                  >
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-button
                    v-if="user.userType == 1"
                    @click="() => handleDelete(row)"
                    type="text"
                    >删除</el-button
                  >
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </myTable>
    </el-card>
    <!-- 抽屉  我要上传-->
    <el-drawer
      title="我要上传"
      :visible.sync="drawer"
      direction="rtl"
      size="500px"
    >
      <!-- 五个模块 -->
      <div class="box-container">
        <div class="box" @click="openDialog(1)">项目BP</div>
        <div class="box" @click="openDialog(2)">访谈纪要</div>
        <div class="box" @click="openDialog(3)">投决报告</div>
        <div class="box" @click="openDialog(4)">立项报告</div>
        <div class="box" @click="openDialog(5)">尽调报告</div>
      </div>
    </el-drawer>
    <!-- --------------------不知道写的什么，代码太乱了，维护性太低---------------------- -->
    <!-- 创建项目BP弹出层 -->
    <createDialogComponent
      v-if="dialogVisibleBP"
      :dialogVisibleOrigin="dialogVisibleBP"
      firstTitle="新建项目BP"
      secondTitle="项目BP"
      attachmentType="1"
      :handleClose="
        () => {
          dialogVisibleBP = false;
          getPageList();
        }
      "
    >
    </createDialogComponent>
    <!-- 创建访谈纪要弹出层 -->
    <createDialogComponent
      v-if="dialogVisibleInterview"
      :dialogVisibleOrigin="dialogVisibleInterview"
      firstTitle="新建访谈纪要"
      secondTitle="访谈纪要"
      attachmentType="2"
      :handleClose="
        () => {
          dialogVisibleInterview = false;
          getPageList();
        }
      "
    >
    </createDialogComponent>
    <!-- 创建投决报告弹出层 -->
    <createDialogComponent
      v-if="dialogVisibleVote"
      :dialogVisibleOrigin="dialogVisibleVote"
      firstTitle="新建投决报告"
      secondTitle="投决报告"
      attachmentType="5"
      :handleClose="
        () => {
          dialogVisibleVote = false;
          getPageList();
        }
      "
    >
    </createDialogComponent>
    <!-- 创建立项报告弹出成 -->
    <createDialogComponent
      v-if="dialogVisibleApproval"
      :dialogVisibleOrigin="dialogVisibleApproval"
      firstTitle="新建立项报告"
      secondTitle="立项报告"
      attachmentType="3"
      :handleClose="
        () => {
          dialogVisibleApproval = false;
          getPageList();
        }
      "
    >
    </createDialogComponent>
    <!-- 创建尽调报告弹出层 -->
    <createDialogComponent
      v-if="dialogVisibleInvestigation"
      :dialogVisibleOrigin="dialogVisibleInvestigation"
      firstTitle="新建尽调报告"
      secondTitle="尽调报告"
      attachmentType="5"
      :handleClose="
        () => {
          dialogVisibleInvestigation = false;
          getPageList();
        }
      "
    >
    </createDialogComponent>
    <!-- 编辑信息 -->
     <editDetal ref="editDetal" @submit="getPageList()"/>
    <detail ref="detail" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import Detail from './detail.vue'
import myTable from "../../components/myTable.vue";
import createDialogComponent from "./components/createDialogComponent.vue"
import editDetal from "./components/editDetal.vue";
export default {
  components: {
    myTable,
    Detail,
    createDialogComponent,
    editDetal
  },
  data() {
    return {
      isSearch: false,
      searchForm: {
        // 文件类型，1项目BP,2访谈纪要,3立项报告,4尽调报告,5投决报告
        attachmentType:'1',
        projectName: "",
        industry: "",
        cityCode: "",
        businessIncome: "",
        netProfit: "",
        projectStatus: "",
        isHot: "",
        isRecommend: ""
      },
      industryTypeList: [],
      addressList: [],
      businessIncomeList: [],
      netProfitList: [],
      operate: {
        DELETE: 1,
        COLLECT: 2,
        DETAIL: 3,
        EDIT: 4
      },
      total: 0,
      tableData: [],
      tableColumns: [
        {
          label: "项目名称",
          prop: "enterpriseName",
          showTooltip: true,
          align: "center",
          minWidth: 200,
          fixed: 'left'
        },
        {
          label: "行业",
          prop: "industryName",
          showTooltip: true,
          align: "center",
          minWidth: 200
        },
        {
          label: "项目简介",
          prop: "projectDescription",
          showTooltip: true,
          align: "center",
          minWidth: 300
        },
        {
          label: "营收",
          prop: "businessIncomeName",
          showTooltip: true,
          align: "center",
          minWidth: 200
        },
        {
          label: "净利规模",
          prop: "netProfitName",
          showTooltip: true,
          align: "center",
          minWidth: 200
        },
        {
          label: "点击量",
          prop: "clickCount",
          showTooltip: true,
          align: "center",
          minWidth: 100,
          sortable: 'custom'
        },
        {
          label: "下载量",
          prop: "downloadCount",
          showTooltip: true,
          align: "center",
          minWidth: 100,
          sortable: 'custom'
        },
        {
          label: "操作",
          slot: "operate",
          width: "80px",
          showTooltip: true,
          align: "center",
          fixed: 'right'
        },
      ],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
      },
      // 点击量下载量排序
      orderType: null,
      loading: false,
      // 抽屉部分
      drawer: false,
      detailDrawer: false,
      // 弹出层部分
      dialogVisibleBP: false,
      dialogVisibleInterview: false,
      dialogVisibleVote: false,
      dialogVisibleApproval: false,
      dialogVisibleInvestigation: false,
      // 存储点击详情的数据
      dataDetail: {},
      activeName: "1",
      // 存储附件
      attachmentList: {
        // 项目BP
        projectBP: [],
        // 访谈纪要
        interview: [],
        // 立项报告
        approval: [],
        // 尽调报告
        investigation: [],
        // 投决报告
        vote: [],
      },
      // 存储项目id
      projectId: "",
      //存储附件数量
      attachmentNum: 0,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  created () {
    this.searchForm.attachmentType = this.$route.query.attachmentType || '1';
  },
  mounted() {
    this.getDropDownData();
    this.handleSearch();
  },
  methods: {
    hotAndRecommend(row, key) {
      this.$msgbox({
          title: '提示',
          message: `${row[key] == '0' ? '设置' : '取消'}${key == 'isHot' ? '热门项目' : '推荐项目'}`,
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = '执行中...';
              this.$http.Post(this.$api.hotAndRecommend, {
                [key]: row[key] == 1 ? 0 : 1,
                [key == 'isHot' ? 'isRecommend' : 'isHot'] : row[[key == 'isHot' ? 'isRecommend' : 'isHot']],
                id: row.id
              }).then(({msg}) => {
                this.$message.success(msg)
                this.getPageList()
              }).finally(() => {
                instance.confirmButtonLoading = false;
                done()
              })
            } else {
              done();
            }
          }
      })
    },
    // 获取下拉数据
    async getDropDownData() {
      // 获取行业 id 1
      let data1 = await this.$http.Get(this.$api.insideListByGroupId, {
        labelGroupId: 1,
      });
      this.industryTypeList = data1.data;

      // 获取营收 id 2
      let data2 = await this.$http.Get(this.$api.insideListByGroupId, {
        labelGroupId: 2,
      });
      this.businessIncomeList = data2.data;

      // 获取净利规模 id 3
      let data3 = await this.$http.Get(this.$api.insideListByGroupId, {
        labelGroupId: 3,
      });
      this.netProfitList = data3.data;

      // 获取地区
      let data4 = await this.$http.Get(this.$api.getAddressList, { level: 2 });
      this.addressList = data4.data.children;
      console.log("addresslist", this.addressList);
    },
    handleReset() {
      this.pageInfo.pageNum = 1;
      this.searchForm = {
        name: "",
        industry: "",
        address: "",
        revenue: "",
        netProfit: "",
        projectStatus: "",
        isHot: "",
        isRecommend: ""
      };
      this.handleSearch();
    },
    handleSearch() {
      this.pageInfo.pageNum = 1;
      this.getPageList();
    },
    // 获取数据
    getPageList() {
      const params = {
        ...this.searchForm,
        ...this.pageInfo,
        fileStatus:2,
        orderType: this.orderType
      };
      if (params.industry) params.industryList = [params.industry]
      delete params.industry
      this.$http.Post(this.$api.projectList, params).then((res) => {
        // console.log(res, "res---------")
        this.total = Number(res.data.total);
        this.tableData = res.data.records;
      });
    },
    handleChange(val) {
      this.pageInfo = { ...val };
      this.getPageList();
    },
    // 排序
    handleSortChange({ prop, order }) {
      if (prop == 'clickCount') this.orderType = order == 'ascending' ? 2 : order == 'descending' ? 1 : null
      if (prop == 'downloadCount') this.orderType = order == 'ascending' ? 3 : order == 'descending' ? 4 : null
      this.getPageList()
    },
    // 表格选择
    handleSelectionChange(val) {
      console.log("表格选择", val);
    },
    // 操作函数
    handleOperation(row, type) {
      // console.log('查看详情')
      if (type === this.operate.COLLECT) {
        this.handleCollect(row)
      } else if (type === this.operate.DETAIL) {
        this.detailDrawer = true;
        this.dataDetail = row;
        this.projectId = row.id;
        this.$refs.detail.open(row.id)
        // this.detailGetData(this.projectId);
        // this.getAttachmentNum(this.activeName);
      }else if(type === this.operate.DELETE) {
        this.changeProject(row)
      } else if(type === this.operate.EDIT) {
        this.$refs.editDetal.init(row)
      }
    },
    // 项目下架
    async changeProject({id, projectStatus}) {
      this.$confirm(`确定要${projectStatus == 2 ? '下架' : '上架'}当前项目吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          await this.$http.Post(this.$api.updateStatus, {
            id:id,
            projectStatus: projectStatus == 2 ? 3 : 2,
          });
          this.$message({
            type: 'success',
            message: '操作成功'
          }); 
          this.handleSearch()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
    },
    // 项目置顶
    async handleTop({ id, isTop }) {
      this.$confirm(`确定要${isTop == 1 ? '取消置顶' : '置顶'}当前项目吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          await this.$http.Post(this.$api.topProject, {
            id:id,
            isTop: isTop == 0 ? 1 : 0,
          });
          this.$message({
            type: 'success',
            message: '操作成功'
          }); 
          this.handleSearch()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
    },
    // 项目删除
    async handleDelete({id}) {
      this.$confirm(`确定要删除当前项目吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.$http.Post(this.$api.deleteProject, {
            id:id
          }).then(({ code, msg }) => {
            if (code == 200) {
              this.$message.success(msg)
              this.handleSearch()
            } else {
              this.$message.error(msg)
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
    },
    async handleCollect(row) {
      const params = {
        collectType:1,
        projectId:row.id,
        projectName:row.projectName
      }
      if(row.collected == 1) {
        let res = await this.$http.DeleteByData(this.$api.cancelCollect,  params);
        if (res.code == 200) {
          this.$message.success('取消收藏成功')
        }
      }else {
        let res = await this.$http.Post(this.$api.addCollectRecord,  params );
        if (res.code == 200) {
          this.$message.success('收藏成功')
        }
      }
      this.getPageList()
    },
    
    // 处理附类型
    handleAttachmentType(type, attachment) {
      if (type == 1) {
        this.attachmentList.projectBP.push(attachment);
      } else if (type == 2) {
        this.attachmentList.interview.push(attachment);
      } else if (type == 3) {
        this.attachmentList.approval.push(attachment);
      } else if (type == 4) {
        this.attachmentList.investigation.push(attachment);
      } else if (type == 5) {
        this.attachmentList.vote.push(attachment);
      }
    },
    // 获取附件数
    async getAttachmentNum(type) {
      let res = await this.$http.Get(this.$api.getAttachmentCount, {
        projectId: this.projectId,
        attachmentType: type,
      });
      if (res.code == 200) {
        this.attachmentNum = res.data.bpCount;
      }
    },
    // 打开弹窗
    openDialog(type) {
      if (type == 1) {
        this.dialogVisibleBP = true;
      } else if (type == 2) {
        this.dialogVisibleInterview = true;
      } else if (type == 3) {
        this.dialogVisibleVote = true;
      } else if (type == 4) {
        this.dialogVisibleApproval = true;
      } else if (type == 5) {
        this.dialogVisibleInvestigation = true;
      }
      this.drawer = false;
    },
    // 切换tab方法
    handleClick(tab) {
      // console.log(tab, event);
      this.activeName = tab.name;
      if (this.activeName != "6") {
        this.getAttachmentNum(this.activeName);
      }
    },
    // 详情关闭的回调放发
    detailDrawerHandleClose() {
      this.attachmentList = {
        projectBP: [],
        interview: [],
        approval: [],
        investigation: [],
        vote: [],
      };
      this.activeName = "1";
      this.attachmentNum = 0;
      this.detailDrawer = false;
      console.log("详情关闭的回调放发");
    },
    // 收藏方法
    collectProjectFun() {
      this.$message.warning("收藏接口还未对接"); 
    },
    // 编辑方法
    editProjectFun() {
      this.$message.warning("编辑接口还未对接"); 
    },
    // 删除方法
    deleteProjectFun() {
      this.$message.warning("删除接口还未对接"); 
    },
  },
};
</script>
<style lang="less" scoped>
.projectList {
  .tabHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
  }
  .title-box {
    display: flex;

    .title-right {
      margin-left: 10px;
      padding: 10px;

      .title {
        font-size: 18px;
        color: #333;
      }

      .content {
        font-size: 14px;
        color: #666;
      }
    }
  }

  .mb-16 {
    margin-bottom: 16px;
  }

  .box-container {
    display: flex;
    flex-wrap: wrap;

    .box {
      width: 200px;
      height: 150px;
      border: 1px solid #ccc;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      margin: 24px;
      transition: all 0.3s ease;
      color: #777;
      cursor: pointer;

      &:hover {
        background-color: #ddd;
        color: #000;
      }
    }
  }
  .detail-drawer {
    .detail-box {
      .detail-top {
        display: flex;
        .detail-top-left {
          padding: 10px 20px;
          width: 80%;
          height: 120px;
          box-sizing: border-box;
          // border:1px solid #ccc;
          display: flex;
          .img {
            width: 80px;
            background-color: #ccc;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            border-radius: 6px;
          }
          .content {
            margin-left: 10px;
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            > div {
              color: #888;
              font-size: 14px;
            }
            .content-title {
              color: black;
              font-size: 22px;
              font-weight: bold;
            }
          }
        }
        .detail-top-right {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .divide {
        height: 20px;
        border-bottom: 1px solid #ccc;
        // color:#666;
        font-weight: bold;
        text-align: center;
        padding-bottom: 10px;
      }
    }
  }
}
</style>
<style lang="less"></style>

<template>
  <div class="new-project">
    <el-dialog
      top="10vh"
      :visible.sync="dialogVisible"
      width="800px"
      title="编辑"
      center
      :before-close="handleCancel"
    >
      <el-form
        ref="projectFormRef"
        :model="formData"
        :rules="formDataRules"
        label-width="110px"
      >
        <el-form-item label="项目名称" prop="enterpriseName">
          <!-- 模糊查询的 -->
          <!-- <el-autocomplete
            style="width: 100%"
            class="inline-input"
            v-model="formData.enterpriseName"
            :fetch-suggestions="querySearch"
            placeholder="请输入内容"
            @select="handleSelect"
          ></el-autocomplete> -->
          <el-input
            v-model="formData.enterpriseName"
            placeholder="请输入项目名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="行业关键词" prop="industry">
          <el-select
            style="width: 100%"
            v-model="formData.industry"
            placeholder="请选择行业关键词"
            :multiple-limit="3"
            multiple
            filterable
            clearable
          >
            <el-option
              v-for="item in industryTypeList"
              :label="item.labelName"
              :value="item.id"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目所在地" prop="address">
          <el-cascader
            v-model="formData.address"
            :options="addressList"
            :props="{
              label: 'name',
              value: 'id'
            }"
            @change="addResshandleChange"></el-cascader>
          <!-- <el-select
            style="width: 100%"
            v-model="formData.cityCode"
            @change="handleCityChange"
            placeholder="请选择项目所在地"
          >
            <el-option-group
              v-for="addressItem in addressList"
              :key="addressItem.id"
              :label="addressItem.name"
            >
              <el-option
                v-for="item in addressItem.children"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-option-group>
          </el-select> -->
        </el-form-item>
        <el-form-item label="财务数据" prop="financialData">
          <div style="display: flex; justify-content: space-between">
            <el-select
              style="width: 100%"
              v-model="formData.businessIncome"
              placeholder="请选择最近财年营业总收入"
              filterable
              clearable
            >
              <el-option
                v-for="item in businessIncomeList"
                :label="item.labelName"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
            <el-select
              style="width: 100%"
              v-model="formData.netProfit"
              placeholder="请选择最近财年规模净利润"
              filterable
              clearable
            >
              <el-option
                v-for="item in netProfitList"
                :label="item.labelName"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
          </div>
        </el-form-item>
        <!-- <el-form-item label="企业联系人" prop="enterpriseContact">
          <el-input
            v-model="formData.enterpriseContact"
            placeholder="请输入企业联系人"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="企业联系方式" prop="enterpriseContactWay">
          <el-input
            v-model="formData.enterpriseContactWay"
            placeholder="请输入企业联系方式"
            clearable
          ></el-input>
        </el-form-item> -->
        <el-form-item label="项目简介" prop="projectDescription">
          <el-input
            v-model="formData.projectDescription"
            placeholder="请输入项目简介"
            maxlength="22"
            show-word-limit
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="封面图" prop="cover">
          <el-upload
            ref="fileUploadBtn"
            class="avatar-uploader"
            :limit="1"
            :show-file-list="false"
            :action="baseRequestUrl + $api.uploadFile"
            :headers="{
                token
            }"
            :on-success="handleSuccess"
            :on-remove="handleRemove">
            <img v-if="formData.cover" :src="formData.cover" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <!-- <el-input
            v-model="formData.cover"
            placeholder="请输入企业联系方式"
            clearable
          ></el-input> -->
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: flex-end; margin-right: 20px"
      >
        <el-button size="small" @click="handleCancel">取 消</el-button>
        <el-button size="small" type="primary" @click="handleSubmit"
          >提 交</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import PDF from "@/assets/fileIcon/pdf.png";
import PPT from "@/assets/fileIcon/ppt.png";
import WORD from "@/assets/fileIcon/word.png";
import { baseRequestUrl } from '@/public/api.js'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      baseRequestUrl,
      data: {},
      dialogVisible: this.dialogVisibleOrigin,
      // 存储文件类型
      fileIconMap: {
        1: PDF,
        2: WORD,
        3: PPT,
      },
      bpList: [
        // {
        //   src: PDF,
        //   name: "项目BP文件.ppt",
        //   size: "22MB"
        // },
        // {
        //   src: WORD,
        //   name: "项目BP文件.ppt",
        //   size: "22MB"
        // }
      ],
      // 需要提交的字段
      formData: {
        // 项目id
        id: "",
        // 项目名称
        enterpriseName: "",
        // 行业关键词
        industry: "",
        // 项目所在地
        cityCode: "",
        cityName: "",
        provinceCode: "",
        provinceName: "",
        address: [],
        // 财务数据
        businessIncome: "",
        netProfit: "",
        // 企业联系人
        // enterpriseContact: "",
        // 企业联系方式
        // enterpriseContactWay: "",
        // 封面图
        cover: '',
        // 介绍
        projectDescription: ''
      },
      industryTypeList: [],
      addressList: [],
      businessIncomeList: [],
      netProfitList: [],
      // 上传数组下表
      uploadFileFlag: 0,
      // 表单规则
      formDataRules: {
        enterpriseName: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
        ],
        industry: [
          {
            required: true,
            validator: (rule, value, callback) => {
              console.log(value)
              if (value.length < 2 || value.length > 3) {
                return callback(new Error("请至少勾选两个，最多三个关键词"));
              }
              callback();
            },
            trigger: "change"
          },
        ],
        cityCode: [
          { required: true, message: "请选择项目所在地", trigger: "change" },
        ],
        financialData: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!this.formData.businessIncome && !this.formData.netProfit) {
                return callback(new Error("两项至少填一项"));
              }
              callback();
            },
            trigger: "change",
          },
        ],
        // enterpriseContact: [
        //   { required: true, message: "请输入企业联系人", trigger: "blur" },
        // ],
        // enterpriseContactWay: [
        //   { required: true, message: "请输入企业联系方式", trigger: "blur" },
        // ],
      },
      // 定义数据存储文件
      fileList: [],
      // 定义通过查询项目名返回的数据
      projectList: [],
      // 定义附件数
      attachmentNum: 0,
    };
  },
  computed: {
    ...mapState(['token']),
  },
  mounted() {
    this.getIndustryList();
  },
  methods: {
    handleSuccess({ data: { fileUrl = '' } = {} }) {
      this.$refs.fileUploadBtn.clearFiles();
      console.log(fileUrl)
      this.formData.cover = fileUrl
      // this.fileList = [{ url: fileUrl }]
    },
    handleRemove() {
      this.formData.cover = ''
      // this.fileList = []
    },
    // 城市选择---原来开发就不管省份了？
    addResshandleChange(e) {
      const provinc = this.addressList.find(i => i.id == e[0])
      const city = provinc.children.find(i => i.id == e[1])
      this.formData.provinceCode = provinc.id
      this.formData.provinceName = provinc.name
      this.formData.cityCode = city.id
      this.formData.cityName = city.name

    },
    async init(data) {
      this.data = data
      this.dialogVisible = true
      Object.keys(this.formData).forEach(key => {
        if (key == 'address') {
          this.formData[key] = [data.provinceCode, data.cityCode].filter(i => i)
          return
        }
        if (key == 'industry') {
          return this.formData[key] = data.industryList || []
        }
        if (key == 'cover') {
          this.fileList = [{url: data[key] }]
        }
        this.formData[key] = data[key]
      })
      await this.$nextTick()
      this.$refs.projectFormRef?.clearValidate()
    },
    // 获取行业下拉列表
    async getIndustryList() {
      let data1 = await this.$http.Get(this.$api.insideListByGroupId, {
        labelGroupId: 1,
      });
      this.industryTypeList = data1.data;

      // 获取营收 id 2
      let data2 = await this.$http.Get(this.$api.insideListByGroupId, {
        labelGroupId: 2,
      });
      this.businessIncomeList = data2.data;

      // 获取净利规模 id 3
      let data3 = await this.$http.Get(this.$api.insideListByGroupId, {
        labelGroupId: 3,
      });
      this.netProfitList = data3.data;

      // 获取地区
      let data4 = await this.$http.Get(this.$api.getAddressList, { level: 2 });
      this.addressList = data4.data.children;
      console.log("addresslist", this.addressList);
    },
    // 上传文件的方法
    async handleUploadFileChange(file) {
      if (!this.formData.enterpriseName) {
        this.$message.error("请先输入项目名称");
        return;
      } else {
        // 只有当以上传的文件数小于2时才允许上传
        if (this.uploadFileFlag < 2 - this.attachmentNum) {
          let fd = new FormData();
          fd.append("file", file.raw);
          // 上传文件
          const res = await this.$http.Post(this.$api.insideUploadFile, fd);
          console.log("res", res);
          this.$message.success("上传成功");
          // this.formData.investProjectAttachments[this.uploadFileFlag] = {};
          // this.formData.investProjectAttachments[this.uploadFileFlag].attachmentType = Number(this.attachmentType);
          // this.formData.investProjectAttachments[this.uploadFileFlag].fileType = res.data.fileType || 1;
          // this.formData.investProjectAttachments[this.uploadFileFlag].fileSize = res.data.fileSize;
          // this.formData.investProjectAttachments[this.uploadFileFlag].fileName = res.data.originName;
          // this.formData.investProjectAttachments[this.uploadFileFlag].fileUrl = res.data.fileUrl;
          let obj = {};
          obj.attachmentType = Number(this.attachmentType);
          obj.fileType = res.data.fileType || 1;
          obj.fileSize = res.data.fileSize;
          obj.fileName = res.data.originName;
          obj.fileUrl = res.data.fileUrl;
          this.formData.investProjectAttachments.push(obj);
          console.log("xxxxx", this.formData.investProjectAttachments);
          this.uploadFileFlag++;
          // 上传附件
          // const res2 = await this.$http.Post(this.$api.uploadAttachment, this.formData);
          // console.log("res2", res2);
        }
      }
    },
    // 删除BP的方法
    handleDelete(item, index) {
      this.formData.investProjectAttachments.splice(index, 1);
    },
    // 提交方法
    handleSubmit() {
      this.$refs.projectFormRef.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        const params = {
          ...this.formData,
          id: this.data.id
        }
        if (params.industry.length) params.industryList = params.industry
        delete params.industry
        let res = await this.$http.Post(this.$api.updateInvestProjectInfo, params);
        if (res.code == 200) {
          this.$message.success("创建成功！");
          this.$emit('submit')
          this.handleCancel();
        }
      });
    },
    // 取消方法
    handleCancel() {
      this.dialogVisible = false;
      this.fileList = []
      // 清空数据
      this.formData = {
        // 项目名称
        enterpriseName: "",
        // 行业关键词
        industry: "",
        // 项目所在地
        cityCode: "",
        cityName: "",
        provinceCode: "",
        provinceName: "",
        address: [],
        // 财务数据
        businessIncome: "",
        netProfit: "",
        // 企业联系人
        // enterpriseContact: "",
        // 企业联系方式
        // enterpriseContactWay: "",
        // 封面图
        cover: '',
        // 介绍
        projectDescription: ''
      };
      this.bpList = [];
      this.uploadFileFlag = 0;
      this.attachmentNum = 0;
    },
    // 选择行业后
    handleCityChange(val) {
      // 将所有的地区都放在一个数组中
      let cityList = [];
      for (let i = 0; i < this.addressList.length; i++) {
        cityList.push(...this.addressList[i].children);
      }
      this.formData.cityName = cityList.find((item) => item.id == val).name;
      console.log("cityList", this.formData.cityName, val);
    },
    // 查询项目建议
    querySearch(queryString, cb) {
      this.$http
        .Post(this.$api.getProjectInfoByCondition, {
          enterpriseName: queryString,
        })
        .then((res) => {
          this.projectList = res.data;
          this.projectList.forEach((item) => {
            item.value = item.enterpriseName;
          });
          cb(this.projectList);
        });
    },
    // 选中项目
    handleSelect(item) {
      console.log("item", item);
      this.formData.id = item.id;
      this.formData.industry = item.industry;
      this.formData.cityCode = item.cityCode;
      this.formData.cityName = item.cityName;
      this.formData.businessIncome = item.businessIncome;
      this.formData.netProfit = item.netProfit;
      // this.formData.enterpriseContact = item.enterpriseContact;
      // this.formData.enterpriseContactWay = item.enterpriseContactWay;
      this.formData.cover = item.cover
      this.formData.projectDescription = item.projectDescription || ''
      this.getAttachmentNum();
    },
    // 获取附件数
    async getAttachmentNum() {
      let res = await this.$http.Get(this.$api.getAttachmentCount, {
        projectId: this.formData.id,
        attachmentType: this.attachmentType,
      });
      if (res.code == 200) {
        this.attachmentNum = res.data.bpCount || 0;
      }
    },
    // 不允许上传提示
    handleUploadDisabled() {
      if(this.attachmentNum >= 2){
        this.$message.warning("附件数量已达上限，不可再上传");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.new-project {
  .title {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .upload-box {
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 120px;
      height: 120px;
      line-height: 120px;
      text-align: center;
      background-color: #eee;
      margin-bottom: 20px;
      margin-left: 20px;
    }

    .avatar {
      width: 120px;
      height: 120px;
      display: block;
    }
  }
    /deep/.avatar-uploader{
      .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }
      .el-upload:hover {
        border-color: #409EFF;
      }
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }

  .bp-list {
    // border:1px solid black;
    .bp-item {
      height: 120px;
      margin: 10px;
      display: flex;

      .bp-box {
        height: 100%;
        border: 1px solid #ccc;
        box-sizing: border-box;
        width: 80%;
        display: flex;

        .bp-left {
          width: 100px;
          display: flex;
          align-items: center;
          background-color: #eee;
          justify-content: center;

          // border: 1px solid black;
          img {
            width: 90px;
            height: 90px;
          }
        }

        .bp-right {
          flex: 1;
          padding: 30px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }

      .bp-delete {
        flex: 1;
        // border:1px solid red;
        display: flex;
        align-items: center;
        padding-left: 20px;

        i {
          font-size: 24px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
<style lang="less"></style>
